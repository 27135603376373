<template>
  <div class="table-wrap">
    <div class="component-tit">
      <span>{{ taskInfo.taskName }}</span>
      <a-button type="primary" @click="back">
        {{ $t("go_back") }}
        <!-- 返回 -->
      </a-button>
    </div>
    <div class="filter-wrap" id="pageTop">
      <!-- 状态 -->
      <ListFilter
        only
        :name="$t('CM_Status')"
        :allObj="{ name: $t('CM_All'), id: -1 }"
        :dataSource="statusOptions"
        @onChange="switchStatus"
      >
        <template v-slot:right>
          <div class="search">
            <a-input-search
              v-model:value="queryParam.nameOrAccount"
              :placeholder="$t('teaching.please_enter_name_account')"
              style="width: 220px"
              enter-button
              allow-clear
              @search="handleSearch"
            />
          </div>
        </template>
      </ListFilter>
    </div>
    <a-tabs
      class="stage-tabs"
      v-model:activeKey="activeStageKey"
      @change="tabChange"
    >
      <a-tab-pane
        v-for="item in stages"
        :key="item.stageId"
        :tab="item.stageName"
      />
    </a-tabs>
    <a-table
      :scroll="{ x: 884 }"
      :columns="columns"
      :data-source="state.dataSource"
      :rowKey="(record, index) => index"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #realName="{ record }">
        <OpenData type="userName" :openid="record.realName" />
        ({{ record.account }})
      </template>
      <template #departmentName="{ record }">
        <a-tooltip placement="topLeft">
          <template #title>
            <template
              v-for="(str, strIndex) in record.departmentPath.split('/')"
              :key="strIndex"
            >
              <OpenData type="departmentName" :openid="str" />
              <span
                v-if="strIndex + 1 < record.departmentPath.split('/').length"
                >/</span
              >
            </template>
          </template>
          <OpenData type="departmentName" :openid="record.departmentName" />
        </a-tooltip>
      </template>
      <template #summarizeCompletedType="{ record }">
        <span v-if="record.summarizeCompletedType == 1">{{
          $t("teaching.submit_summary_complete")
        }}</span>
        <!-- 提交总结完成 -->
        <span v-else-if="record.summarizeCompletedType == 2">{{
          $t("teaching.approved_qualified")
        }}</span>
        <!-- 批阅合格完成 -->
      </template>
      <template #reviewResult="{ record }">
        <template v-if="record.reviewStatus == 1">
          <span style="color: #44b44f" v-if="record.reviewResult == 1">{{
            $t("teaching.excellent")
          }}</span>
          <!-- 优秀 -->
          <span style="color: #44b44f" v-else-if="record.reviewResult == 2">
            <template v-if="record.scoreType == 1"
              >{{ record.reviewScore }} / </template
            >{{ $t("teaching.qualified") }}</span
          >
          <!-- 合格 -->
          <span style="color: #e8993e" v-else-if="record.reviewResult == 3"
            ><template v-if="record.scoreType == 1"
              >{{ record.reviewScore }} /
            </template>
            {{ $t("teaching.unqualified") }}</span
          >
          <!-- 不合格 -->
          <span v-else>{{ $t("LB_Teacher_HavaPerusal") }}</span>
          <!-- 已批阅 -->
        </template>
        <span style="color: #e8673e" v-else>{{
          $t("XB_Exam_PendingApproval")
        }}</span>
        <!-- 待批阅 -->
      </template>
      <template #action="{ record }">
        <div class="action">
          <a-button type="link" @click="toDetail(record)">{{
            $t("cm_view")
          }}</a-button>
          <!-- 查看 -->
        </div>
      </template>
    </a-table>
  </div>
  <!-- 总结批阅 -->
  <a-modal
    v-model:visible="visible"
    :title="$t('teaching.summary_review')"
    width="800px"
    :maskClosable="false"
  >
    <p>{{ $t("teaching.require") }}：{{ formData.sumRequirement }}</p>
    <!-- 总结要求： -->
    <p v-if="formData.scoreType == 1">
      <!-- 总结总分： -->
      {{ $t("teaching.total_score") }}：{{ formData.totalScore }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {{ $t("teaching.passing_score") }}：{{
        parseInt(formData.totalScore * (formData.passLine / 100))
      }}
      <!-- 合格总分： -->
    </p>
    <p v-if="formData.scoreType == 1">
      <!-- 提交人： -->
      {{ $t("teaching.submitter") }}：{{ formData.realName }}
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      {{ $t("CM_SubmitTime") }}：{{ dateFormat(formData.sumSubmitTime) }}
      <!-- 提交时间： -->
    </p>
    <div class="flex-box">
      <span class="label">{{ $t("teaching.summary_content") }}：</span>
      <!-- 总结内容： -->
      <a-textarea
        style="width: 675px"
        v-model:value="formData.userSum"
        :rows="6"
        disabled
      />
    </div>
    <template v-if="formData.attachments && formData.attachments.length > 0">
      <div class="files">
        <span class="label">{{ $t("CM_LB_AttachmentFile") }}：</span>
        <!-- 附件： -->
        <ul>
          <li v-for="(item, index) in formData.attachments" :key="index">
            <PaperClipOutlined style="font-size: 18px" />
            <span class="title" @click="viewOrDownFile(item)">
              <a-tooltip>
                <template #title>
                  <template v-if="item.secretData">
                    {{ htsySecret[item.secretData] }}
                  </template>
                  {{ item.fileName }}
                </template>
                <template v-if="item.secretData">
                  {{ htsySecret[item.secretData] }}
                </template>
                {{ item.fileName }}
                {{ item.filePath.substr(item.filePath.lastIndexOf(".")) }}
              </a-tooltip>
            </span>
          </li>
        </ul>
      </div>
    </template>
    <div class="flex-box" v-if="formData.scoreType == 1">
      <span class="label">{{ $t("LB_Favorite_Score") }}：</span>
      <!-- 评分： -->
      <a-input-number
        v-model:value="formData.reviewScore"
        :min="0"
        :max="formData.totalScore"
        :precision="0"
        :disabled="formData.reviewStatus == 1"
      />
      <span>
        <!-- 满分 分 -->
        &nbsp;&nbsp;&nbsp;&nbsp; {{ $t("LB_Favorite_Score") }}：
        {{ $t("teaching.fill_score", [formData.totalScore]) }}&nbsp;&nbsp;
        {{
          $t("teaching.num_score_pass", [
            parseInt(formData.totalScore * (formData.passLine / 100)),
          ])
        }}</span
      >
      <!-- 分合格 -->
    </div>
    <div class="flex-box" v-if="formData.scoreType == 2">
      <span class="label">{{ $t("LB_Favorite_Score") }}：</span>
      <!-- 评分： -->
      <a-radio-group
        v-model:value="formData.reviewResult"
        :disabled="formData.reviewStatus == 1"
      >
        <a-radio :value="1">{{ $t("teaching.excellent") }}</a-radio>
        <!-- 优秀 -->
        <a-radio :value="2">{{ $t("teaching.qualified") }}</a-radio>
        <!-- 合格 -->
        <a-radio :value="3">{{ $t("teaching.unqualified") }}</a-radio>
        <!-- 不合格 -->
      </a-radio-group>
    </div>
    <div class="flex-box">
      <span class="label">{{ $t("CM_LB_Reviews") }}：</span>
      <!-- 评语： -->
      <a-textarea
        style="width: 675px"
        v-model:value="formData.reviewComment"
        :rows="6"
        :maxlength="2000"
        showCount
        :placeholder="$t('teaching.please_enter_a_comment')"
        :disabled="formData.reviewStatus == 1"
      />
      <!-- 请输入评语 -->
    </div>
    <template #footer>
      <a-button @click="visible = false">{{ $t("CM_Cancel") }}</a-button>
      <!-- 取消 -->
      <a-button
        v-if="formData.reviewStatus != 1"
        type="primary"
        @click="submit"
        >{{ $t("CM_Confirm") }}</a-button
      >
      <!-- 确定 -->
    </template>
  </a-modal>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { teachManageReviewList, teachManageReview } from "@/api/map";
import ListFilter from "@/components/new/filter/ListFilter.vue";
import OpenData from "@/components/OpenData.vue";
import { dateFormat, viewOrDownFile } from "@/utils/tools";
import { htsySecret } from "@/utils/business";
export default {
  components: {
    ListFilter,
    OpenData,
  },
  props: {
    taskInfo: {
      type: Object,
      default: {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      statusOptions: [
        { name: $t("XB_Exam_PendingApproval"), id: 0 }, // 待批阅
        { name: $t("teaching.excellent"), id: 1 }, // 优秀
        { name: $t("teaching.qualified"), id: 2 }, // 合格
        { name: $t("teaching.unqualified"), id: 3 }, // 不合格
      ],
      stages: [],
      activeStageKey: 0,
      columns: [
        {
          title: $t("teaching.table.name_account"),
          // title: "姓名（账号）",
          dataIndex: "realName",
          slots: { customRender: "realName" },
          width: 180,
          ellipsis: true,
        },
        {
          title: $t("teaching.table.department"),
          // title: "部门",
          width: 170,
          slots: { customRender: "departmentName" },
          ellipsis: true,
        },
        {
          title: $t("teaching.table.resource_name"),
          // title: "资源名称",
          dataIndex: "resourceName",
          width: 170,
          ellipsis: true,
        },
        {
          title: $t("teaching.table.completion_standard"),
          // title: "完成标准",
          width: 200,
          align: "center",
          slots: { customRender: "summarizeCompletedType" },
        },
        {
          title: $t("teaching.table.status"),
          // title: "状态",
          width: 130,
          align: "center",
          slots: { customRender: "reviewResult" },
        },
        {
          title: $t("teaching.table.operate"),
          // title: "操作",
          width: 80,
          align: "center",
          slots: { customRender: "action" },
          fixed: "right",
        },
      ],
      visible: false,
      formData: {},
    });

    let queryParam = reactive({
      nameOrAccount: "",
      taskId: props.taskInfo.taskId,
      evaluationStatus: -1,
      planStatus: -1,
      reviewStatus: -1,
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return teachManageReviewList(form).then((res) => {
        if (!queryParam.stageId) {
          stateData.stages = res.data.stages;
          stateData.activeStageKey = res.data.stages[0].stageId;
        }
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 30,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const back = () => {
      emit("back");
    };

    const switchStatus = (arr) => {
      queryParam.reviewStatus = arr[0];
      handleSearch();
    };

    const tabChange = (e) => {
      queryParam.stageId = e;
      handleSearch();
    };

    const toDetail = (item) => {
      stateData.formData = item;
      if (item.reviewStatus != 1) {
        stateData.formData.reviewResult = item.reviewResult || 3;
      }
      stateData.visible = true;
    };

    const submit = () => {
      let params = {
        taskId: stateData.formData.taskId,
        detailId: stateData.formData.detailId,
        userId: stateData.formData.userID,
        reviewScore:
          stateData.formData.reviewScore < 0
            ? 0
            : stateData.formData.reviewScore,
        reviewResult: stateData.formData.reviewResult,
        comments: stateData.formData.reviewComment,
      };
      teachManageReview(params).then((res) => {
        if (res.ret == 0) {
          reload();
          proxy.$message.success("操作成功！");
          stateData.visible = false;
        }
      });
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      back,
      switchStatus,
      tabChange,
      toDetail,
      viewOrDownFile,
      submit,
      htsySecret,
    };
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  .component-tit {
    font-size: 20px;
    line-height: 26px;
    color: #333;
    font-weight: 600;
    padding: 16px 0 12px;
    .mixinFlex(space-between; center);
  }
  .filter-wrap {
    padding-bottom: 12px;
    margin-bottom: 16px;

    .filter {
      padding: 5px 0;
    }

    .search {
      padding: 5px 0;
      .mixinFlex(flex-end);

      ::v-deep(.ant-input-search) {
        padding-right: 1px;
        border-radius: 4px 0 0 4px;
      }

      ::v-deep(.ant-btn) {
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.files {
  .mixinFlex();
  margin: 14px 0 6px;
  li {
    width: 702px;
    color: @color-theme;
    margin-bottom: 10px;
    .mixinEllipsis(20px);
    span {
      cursor: pointer;
    }
  }
}
.flex-box {
  .mixinFlex();
  margin-bottom: 15px;
  .label {
    width: 150px;
  }
}
.stage-tabs {
  ::v-deep(.ant-tabs-bar) {
    border-bottom: none;
    .ant-tabs-ink-bar {
      background-color: transparent !important;
    }
    .ant-tabs-tab {
      background-color: #f5f7fb;
      padding: 5px 20px 5px 30px;
      color: #999;
      margin-right: 10px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 9px solid #fff;
        border-bottom: 16px solid transparent;
      }
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: -9px;
        width: 0;
        height: 0;
        border-top: 16px solid transparent;
        border-left: 9px solid #f5f7fb;
        border-bottom: 16px solid transparent;
      }
      &:first-child {
        &:before {
          content: none;
        }
      }
      &.ant-tabs-tab-active {
        background-color: #36b5fd;
        color: #fff;
        &:hover {
          color: #fff;
        }
        &:after {
          border-left: 9px solid #36b5fd;
        }
      }
    }
  }
}
</style>
