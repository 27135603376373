<template>
  <div
    class="data-card"
    :class="{ card: listType == 'card', list: listType == 'list' }"
    @click="preview"
  >
    <div class="cover-wrap">
      <div class="cover-content">
        <template v-if="dataSource.resourceType == 11">
          <template v-if="dataSource.certificatePath">
            <img
              v-if="
                dataSource.certificatePath.substr(
                  dataSource.certificatePath.lastIndexOf('.')
                ) == '.pdf'
              "
              style="padding: 12px 0"
              :src="require('@/assets/image/mine/pdf.png')"
              alt="cover"
            />
            <img
              v-else
              v-viewer.static="{ modal: true, title: false }"
              ref="picRef"
              :src="dataSource.certificatePath"
              alt="cover"
            />
          </template>
          <img
            v-else-if="dataSource.resourceType == 11"
            :src="require('@/assets/image/mine/qualification_cover.jpg')"
            alt="cover"
          />
        </template>
        <img v-else :src="dataSource.certificatePath" alt="cover" />
      </div>
    </div>
    <div class="detail">
      <h5>
        <a-popover trigger="hover">
          <template #content>
            <div class="popover-content">
              {{ dataSource.certificateName }}
            </div>
          </template>
          {{ dataSource.certificateName }}
        </a-popover>
      </h5>
      <div class="row mt12">
        {{ $t("LB_Doc_Source") }}：<!-- 来源 -->
        <template v-if="dataSource.resourceType == 0">
          【{{ $t("issued_online")
          }}<!-- 线上颁发 -->】
        </template>
        <template v-else-if="dataSource.resourceType == 1">
          【{{ $t("MN_TrainingProject_MHome")
          }}<!-- 培训项目 -->】
        </template>
        <template v-else-if="dataSource.resourceType == 5">
          【{{ $t("cm_subject")
          }}<!-- 课程专题 -->】
        </template>
        <template v-else-if="dataSource.resourceType == 6">
          【{{ $t("Pub_LP")
          }}<!-- 学习地图 -->】
        </template>
        <template v-else-if="dataSource.resourceType == 10">
          【{{ $t("Sys_Exam")
          }}<!-- 考试 -->】
        </template>
        <template v-else-if="dataSource.resourceType == 9">
          【{{ $t("cm_newstaff")
          }}<!-- 新人培训 -->】
        </template>
        {{ dataSource.resourceName }}
      </div>
      <div class="row space-between" v-show="listType == 'card'">
        <div class="left">
          {{ $t("Valid_until") }}：<!-- 有效期至： -->
          <span class="blue">
            {{
              dataSource.expiredDate > 0
                ? dateFormat(dataSource.expiredDate, "YYYY-MM-DD")
                : $t("permanent")
            }}<!-- 永久 -->
          </span>
        </div>
        <div class="right">
          <span class="green" v-if="dataSource.status == 1">
            {{ $t("Pub_Normal")
            }}<!-- 正常 -->
          </span>
          <span class="red" v-else>
            {{ $t("CM_Expired")
            }}<!-- 已过期 -->
          </span>
        </div>
      </div>
      <div class="row mt12" v-show="listType == 'list'">
        {{ $t("Valid_until") }}：
        <span class="blue">
          <!-- 有效期至： -->{{
            dataSource.expiredDate > 0
              ? dateFormat(dataSource.expiredDate, "YYYY-MM-DD")
              : $t("permanent")
          }}<!-- 永久 -->
        </span>
      </div>
      <div class="row mt12" v-show="listType == 'list'">
        {{ $t("LB_Exam_CertStatus") }}：<!-- 证书状态： -->
        <span class="green" v-if="dataSource.status == 1">
          {{ $t("Pub_Normal")
          }}<!-- 正常 -->
        </span>
        <span class="red" v-else>
          {{ $t("CM_Expired")
          }}<!-- 已过期 -->
        </span>
      </div>
    </div>
    <div class="btns" v-show="listType == 'list'">
      <div class="btn">
        {{ $t("view_detail") }}
      </div>
    </div>
  </div>
  <CertificatePreview ref="CertificatePreviewRef" />
</template>

<script>
import { ref, getCurrentInstance } from "vue";
import { dateFormat } from "@/utils/tools";
import CertificatePreview from "../CertificatePreview.vue";
export default {
  name: "CertificateCard",
  components: { CertificatePreview },
  props: {
    newWindow: {
      type: Boolean,
      default: false,
    },
    listType: {
      type: String,
      default: "card",
    },
    dataSource: {
      type: Object,
      default: {},
    },
  },
  setup(props) {
    const { proxy } = getCurrentInstance();
    const picRef = ref(null);
    const CertificatePreviewRef = ref(null);
    const preview = () => {
      let item = props.dataSource;
      let path = item.certificatePath;
      if (item.resourceType == 11) {
        if (path) {
          if (path.substr(path.lastIndexOf(".")) == ".pdf") {
            window.open(path);
          } else {
            picRef.value.click();
          }
        } else {
          proxy.$message.warn(proxy.$t("NoScannedCopyOfCertificate"));
        }
      } else {
        CertificatePreviewRef.value.initData(item);
      }
    };

    return {
      dateFormat,
      picRef,
      CertificatePreviewRef,
      preview,
    };
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
.data-card {
  &.card {
    .cover-wrap {
      width: 289px;
      height: 163px;
      border-radius: 8px 8px 0 0;
      overflow: hidden;
    }
    .row.space-between {
      .mixinFlex(space-between; center);
    }
  }
  &.list {
    .cover-wrap {
      width: 258px;
      height: 145px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
.cover-content {
  width: 100%;
  height: 100%;
  .mixinFlex(center; center);
  background-image: url("~@/assets/image/mine/certificate_cover_bg.png");
  background-size: 100% 100%;
  img {
    max-width: 100%;
    max-height: 100%;
  }
}
</style>
